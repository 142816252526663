@tailwind base;
@tailwind components;
@tailwind utilities;



h1 {
  font-family: 'Pacifico';
  line-height: 1.5 !important;
}

@layer utilities {
  .snap {
    scroll-snap-type: var(--scroll-snap-direction) var(--scroll-snap-constraint);
  }
  .snap-y {
    --scroll-snap-direction: y;
  }
  .snap-mandatory {
    --scroll-snap-constraint: mandatory;
  }
  .snap-start {
    scroll-snap-align: start;
  }
}