

body {
  scroll-behavior: smooth;
}

/* Adjust the scroll speed (increase the value for slower scrolling) */
.snap-y {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  scroll-padding-top: 0vh; /* Add some padding to each section (you can adjust this value as per your preference) */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  scroll-behavior: smooth;
} 

.App-header {
  background-color: #aeff74;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mobile-nav {
  @apply block md:hidden fixed top-10 py-2 w-full h-full bg-gray-900 duration-500;
} 
.mobile-hero {
  @apply block md:hidden fixed top-10 py-2 w-full h-full bg-gray-900 duration-500;
}
nav li a {
  @apply px-4 py-5 text-lg;
}

nav li a:hover {
  color: rgb(34 211 238);
}

.bg-primary {
  background: rgb(12 74 110);
}

.bg-secondary {
  background: rgb(30 58 138);
}

.bg-accent {
  background: rgb(34 211 238);
}

.text-accent {
  color: rgb(34 211 238);
} 

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: rgb(154, 183, 196);
  border-radius: 5px;
  padding: 20px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  position: relative;
}

.modal-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #a8b6bf;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.close-icon {
  color: rgb(78, 82, 83);
  font-size: 20px;
}